import React, {lazy, Suspense, useEffect, useState} from "react";
import Hotjar from '@hotjar/browser';

// Libraries
import {Route, Routes, useLocation} from "react-router-dom";
import retina from "retinajs";
import {AnimatePresence} from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";
import {VacancyMediorData, VacancySeniorData} from "./Pages/Data";

const VacancyPage = lazy(() => import("./Pages/VacancyPage"));
const OverOnsPage = lazy(() => import("./Pages/OverOnsPage"));
const VoorBedrijvenPage = lazy(() => import("./Pages/VoorBedrijvenPage"));
const WerkenBijPage = lazy(() => import("./Pages/WerkenBijPage"));
const LandingPage = lazy(() => import("./Pages/LandingPage"));
const WetDbaPage = lazy(() => import("./Pages/WetDbaPage"));
const ContactPage = lazy(() => import("./Pages/ContactPage"));
const SalarisCheckPage = lazy(() => import("./Pages/SalarisCheckPage"));
const WinstmodelPage = lazy(() => import("./Pages/WinstmodelPage"));
const NotFoundPage = lazy(() => import("./Pages/404"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
    const [customModal, setCustomModal] = useState({
        el: null,
        isOpen: false
    })
    const location = useLocation();

    // RetinaJS
    useEffect(() => {
        window.addEventListener('load', retina(document.querySelectorAll('img')));
    }, [])

    // Hotjar
    useEffect(() => {
        const siteId = 3740649;
        const hotjarVersion = 6;

        Hotjar.init(siteId, hotjarVersion);
    }, [])

    useEffect(() => {
        setTimeout(() => {
            import("./Functions/Utilities").then(module => {
                module.SetHeaderMenuPos()
                module.setDocumentFullHeight()
            })
        }, 1000);
    }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/over-ons" element={<OverOnsPage />} />
                  <Route path="/voor-bedrijven" element={<VoorBedrijvenPage />} />
                  <Route path="/werken-bij" element={<WerkenBijPage />} />
                  <Route path="/70-30-winstmodel" element={<WinstmodelPage />} />
                  <Route path="/contact" element={<ContactPage />} />

                  {/* Salaris Check */}
                  <Route path="/salaris-check" element={<SalarisCheckPage />} />

                  {/* Vacatures */}
                  <Route path="/vacature/senior-java-developer" element={<VacancyPage vacancy={VacancySeniorData} />} />
                  <Route path="/vacature/medior-java-developer" element={<VacancyPage vacancy={VacancyMediorData} />} />

                  <Route path="/wet-dba" element={<WetDbaPage />} />

                  <Route path="*" element={<NotFoundPage />}/>
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;