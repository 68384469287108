export const EmployeesTestimonialsData = [
    {
        firstname: "Joris",
        lastname: "Klein Tijssink",
        name: "Joris Klein Tijssink",
        designation: "Java Developer",
        content: "Ik werk hier al meer dan 4 jaar inmiddels. Dojoo is een thuis, een plek waar ik kom voor plezier en een plek om me verder te ontwikkelen.",
        img: "/assets/img/profile/joris.jpeg"
    },
    {
        firstname: "Jasper",
        lastname: "Timmer",
        name: "Jasper Timmer",
        designation: "Java Developer",
        content: "Gezelligheid, leuke collega’s en een fijne werksfeer is wat mij bind aan dit bedrijf. Ze staan altijd voor je klaar en ze zijn netjes en op tijd.",
        img: "/assets/img/profile/jasper.jpeg"
    },
    {
        firstname: "Wilmer",
        lastname: "van Heerde",
        name: "Wilmer van Heerde",
        designation: "Java Developer",
        content: "Dojoo heeft me de kans gegeven me te ontwikkelen tot wie ik ben. Er is hier altijd een topspirit. Het is ook erg leuk om interactie te hebben met de marketing dames en de sales heren.",
        img: "/assets/img/profile/wilmer.jpeg"
    },
];

export const VacaturesData = [
    {
        title: "Medior Java Developer",
        subtitle: "32-40 uur",
        img: "/assets/img/webp/medior-developer.webp",
        btnTitle: "Bekijken",
        btnLink: "/vacature/medior-java-developer",
        icon: "line-icon-Profile"
    },
    {
        title: "Senior Java Developer",
        subtitle: "32-40 uur",
        img: "/assets/img/webp/senior-developer.webp",
        btnTitle: "Bekijken",
        btnLink: "/vacature/senior-java-developer",
        icon: "line-icon-Profile"
    },
    {
        title: "Open vacature",
        subtitle: "Mail ons",
        img: "/assets/img/webp/developer.webp",
        btnTitle: "Mail",
        btnLink: "/contact",
        icon: "line-icon-Envelope"
    },
];

export const FAQData = [
    {
        title: "Ik heb een sollicitatie verstuurd, maar hoe gaat het nu verder?",
        content: "Een van onze senior IT’ers zal contact met je opnemen om een kennismakingsgesprek in te plannen."
    },
    {
        title: "Ik zie voor mij geen vacature, kan ik toch solliciteren?",
        content: "Bij Dojoo waarderen we ondernemerschap dus een open sollicitatie wordt altijd gewaardeerd. Dus weet je het niet zeker? Mail of bel ons gewoon en dan drinken we binnenkort een kop koffie samen"
    },
    {
        title: "Mag ik ook thuiswerken?",
        content: "Dat mag zeker!"
    },
    {
        title: "Hoeveel uur kan ik werken bij Dojoo?",
        content: "Bij Dojoo is het mogelijk om 32, 36 of 40 uur te werken."
    },
    {
        title: "Ik zie wel een leuke vacature maar heb nog een vraag. Wat nu?",
        content: "Neem contact met ons op! Stuur ons je vraag en we beantwoorden deze zo goed mogelijk."
    },
    {
        title: "Welke opleidingsmogelijkheden hebben jullie?",
        content: "Dojoo biedt verschillende opleidingsmogelijkheden tijdens je carriere. Dit groeipad is per IT’er verschillend."
    },
    {
        title: "Houd ik nog wel vrije tijd over als ik bij jullie kom werken?",
        content: "Work-lifebalance is belangrijk dus voor vrije tijd is altijd tijd!"
    },
];

export const KlantenData = [
    {
        name: "Homie.nl",
        img: '/assets/img/logo/homie-logo.webp',
        link: "https://homie.nl/",
        target: "_blank"
    },
    {
        name: "Homie Marketing",
        img: '/assets/img/logo/homie-marketing-logo.webp',
        link: "https://restaurants.homie.nl/marketing/",
        target: "_blank"
    },
    {
        name: "DICTU",
        img: '/assets/img/logo/dictu-logo.webp'
    },
    {
        name: "GGD",
        img: '/assets/img/logo/ggd-logo.webp'
    },
    {
        name: "DIJ",
        img: '/assets/img/logo/dij-logo.webp'
    }
];

export const CompanyImages = [
    {
        img: "/assets/img/webp/bedrijfs-borrel.webp",
    },
    {
        img: "/assets/img/webp/bedrijfs-lunch.webp",
    },
    {
        img: "/assets/img/webp/bedrijfsuitje.webp",
    }
];


export const CompanyStatisticsData = [
    {
        icon: "line-icon-Location-2",
        title: "Actief in Nederland",
    },
    {
        icon: "line-icon-Building",
        title: "Hoofdkantoor in Zwolle"
    },
    {
        icon: "line-icon-Ying-Yang",
        title: "Gemiddelde leeftijd is 29 jaar",
    },
    {
        icon: "line-icon-Add-UserStar",
        title: "17 medewerkers in dienst",
    },
    {
        icon: "line-icon-Calendar-4",
        title: "Opgericht in 2018",
    },
    {
        icon: "line-icon-WomanMan",
        title: "67% man / 33% vrouw",
    }
];


export const VacancySeniorData = {
    title: "Senior Java Developer",
    slogan: "Betreedt onze Dojoo als Java Sensei",
    text: [
        "Bij Dojoo dompel je jezelf onder in een technologische dojo waar Java-kunsten naar het hoogste niveau worden getild. Je bouwt aan innovatieve systemen, stroomlijnt onze backend-processen en draagt bij aan projecten die de ruggengraat vormen van talloze digitale ervaringen, gevoeld door ontwikkelaars en gebruikers in heel Nederland. Stel je voor dat je sleutelt aan een dynamisch resource-management systeem, de architectuur ontwerpt voor een next-gen authenticatiesysteem, en leidende technologieën implementeert voor een real-time data-verwerkingstool. Zin om een impact te maken die verder gaat dan code en zichtbaar is voor duizenden? Wil je de traditionele Java-backend landschap transformeren met een vleugje dojo-wijsheid? Dan is dit de plek voor jou!",
        "Als Senior Java Developer bij Dojoo verfijn je niet alleen je eigen kunsten, maar dien je ook als een sensei voor het team. Je ontwikkelt systemen waarbij prestaties, schaalbaarheid en veiligheid voorop staan. Gewapend met de nieuwste technologieën, produceer je code die de essentie van Java meesterschap belichaamt: efficiënt, elegant en voorbereid op de toekomst. Dankzij jouw ervaring en expertise wordt elk project een symfonie van gestroomlijnde logica en innovatie. Jouw code is niet zomaar code - het is een weerspiegeling van vakmanschap, beheersing en passie."
    ],
    benefits: [
        {
            title: 'Een top salaris: 70/30 beloningsstructuur. Jij ontvangt 70% van wat wij aan de klant factureren',
            icon: 'feather-gift',
        },
        {
            title: 'Thuiswerkmogelijkheden, als het past voor onze klant',
            icon: 'feather-check',
        },
        {
            title: 'Flexibele werktijden',
            icon: 'feather-check',
        },
        {
            title: 'Onbeperkte vrije dagen',
            icon: 'feather-check',
        },
        {
            title: 'Keuze tussen een auto van de zaak, een OV-chipkaart of een reisvergoeding',
            icon: 'feather-check',
        },
        {
            title: '15% medewerkerskorting bij online eten bestellingen via Homie.nl',
            icon: 'feather-check',
        }
    ],
    experience: [
        {
            title: 'Je hebt een hbo technische opleiding gevolgd met minstens 5 jaar relevante werkervaring.',
            icon: 'feather-check',
        },
        {
            title: 'Je bent eager om te leren en jouw kennis te delen met collega\'s en klanten en kan ze perfect adviseren om een design te bouwen en te implementeren.',
            icon: 'feather-check',
        },
        {
            title: 'Je kent Java als je broekzak, en heb je ervaring met React, AWS, Azure, Docker en Kubernetes.',
            icon: 'feather-check',
        },
        {
            title: 'Jij bepaalt wat het beste is voor jou, je klant of je project en je hebt geen moeite met het geven van je mening.',
            icon: 'feather-check',
        },
        {
            title: 'Jij ❤️ Code. Buiten werktijd ben je ook bezig met het bouwen van je eigen projecten, applicaties en websites, of je probeert gewoon nieuwe talen, frameworks en technieken uit - omdat het kan.',
            icon: 'feather-check',
        }
    ],
    activities: [
        {
            value: '70%',
            title: 'Programmeren',
        },
        {
            value: '20%',
            title: 'Analyseren',
        },
        {
            value: '10%',
            title: 'Teamoverleg',
        }
    ],
    qualities: [
        {
            icon: "line-icon-Brain-2 text-fastblue",
            title: "Diepgaand Architecturaal Inzicht",
            content: "Jij bezit het vermogen om complexe, schaalbare en modulaire architectuurontwerpen moeiteloos te creëren. Je kiest intuïtief de meest efficiënte datastructuren en algoritmen en maakt keuzes met zowel het heden als de toekomst in gedachten.",
        },
        {
            icon: "line-icon-Tactic text-fastblue",
            title: "Probleemoplossend Vermogen in Complexiteit",
            content: "Je duikt met vertrouwen de diepte in, ontrafelt onbekende systemen en bedenkt vanaf scratch oplossingen voor complexe uitdagingen. Wanneer er technische problemen opduiken, ben jij vaak de eerste die ze hoofd biedt.",
        },
        {
            icon: "line-icon-Sunglasses-Smiley2 text-fastblue",
            title: "Mentorschap & Leiderschap",
            content: "Jouw technische kennis is indrukwekkend, maar wat je echt onderscheidt, is jouw vermogen om junior ontwikkelaars te begeleiden. Je geeft effectief kennis door, voert code reviews uit met oog voor detail en draagt actief bij aan de groei van het team.",
        }
    ],
    techStack: [
        {
            img: "/assets/img/webp/java-icon.webp",
            title: "Java 11, 17 of 21"
        },
        {
            img: "/assets/img/webp/spring-icon.webp",
            title: "Spring Framework"
        },
        {
            img: "/assets/img/webp/postgresql-icon.webp",
            title: "Relationele Database(s)"
        },
        {
            img: "/assets/img/webp/html-css-icon.webp",
            title: "HTML en CSS"
        },
    ]
};

export const VacancyMediorData = {
    title: "Medior Java Developer",
    slogan: "Laat jouw java vaardigheden bloeien in onze Dojoo",
    text: [
        "In Dojoo bevind je je in een technologie-georiënteerde dojo waar je Java-vaardigheden tot grote hoogten ontwikkelt. Je werkt aan vooruitstrevende systemen, optimaliseert onze backend-processen en levert een cruciale bijdrage aan projecten die essentieel zijn voor talrijke digitale toepassingen door heel Nederland. Visualiseer jezelf bezig met het ontwikkelen van een dynamisch resourcebeheersysteem, het ontwerpen van de architectuur voor een geavanceerd authenticatiesysteem, en het implementeren van toonaangevende technologieën in een tool voor real-time dataverwerking. Klaar om een impact te realiseren die verder reikt dan alleen code, en zichtbaar is voor duizenden? Heb je de ambitie om het traditionele Java-backend terrein te vernieuwen met een dosis dojo-wijsheid? Dan ben je bij ons aan het juiste adres!",
        "Als Medior Java Developer bij Dojoo verdiep je je vaardigheden en groei je richting het niveau van een sensei binnen het team. Je werkt aan systemen waarin prestaties, schaalbaarheid en veiligheid centraal staan. Met toegang tot de nieuwste technologieën ontwikkel je code die getuigt van je groeiende beheersing in Java: efficiënt, stijlvol en toekomstgericht. Jouw bijdrage aan elk project brengt een mix van doordachte logica en frisse innovatie. De code die je schrijft is meer dan alleen regels op een scherm – het markeert jouw ontwikkelingspad naar technische excellentie en professionele passie."
    ],
    benefits: [
        {
            title: 'Een top salaris: 70/30 beloningsstructuur. Jij ontvangt 70% van wat wij aan de klant factureren',
            icon: 'feather-gift',
        },
        {
            title: 'Thuiswerkmogelijkheden, als het past voor onze klant',
            icon: 'feather-check',
        },
        {
            title: 'Flexibele werktijden',
            icon: 'feather-check',
        },
        {
            title: 'Onbeperkte vrije dagen',
            icon: 'feather-check',
        },
        {
            title: 'Keuze tussen een auto van de zaak, een OV-chipkaart of een reisvergoeding',
            icon: 'feather-check',
        },
        {
            title: '15% medewerkerskorting bij online eten bestellingen via Homie.nl',
            icon: 'feather-check',
        }
    ],
    experience: [
        {
            title: 'Je hebt een hbo technische opleiding gevolgd met minstens 3 jaar relevante werkervaring.',
            icon: 'feather-check',
        },
        {
            title: 'Je bent eager om te leren en jouw kennis te delen met collega\'s en klanten en kan ze perfect adviseren om een design te bouwen en te implementeren.',
            icon: 'feather-check',
        },
        {
            title: 'Je bent een gevorderde Javaan, en hebt ervaring met technieken daar omheen.',
            icon: 'feather-check',
        },
        {
            title: 'Jij bepaalt wat het beste is voor jou, je klant of je project en je hebt geen moeite met het geven van je mening.',
            icon: 'feather-check',
        },
        {
            title: 'Jij ❤️ Code. Buiten werktijd ben je ook bezig met het bouwen van je eigen projecten, applicaties en websites, of je probeert gewoon nieuwe talen, frameworks en technieken uit - omdat het kan.',
            icon: 'feather-check',
        }
    ],
    activities: [
        {
            value: '70%',
            title: 'Programmeren',
        },
        {
            value: '20%',
            title: 'Analyseren',
        },
        {
            value: '10%',
            title: 'Teamoverleg',
        }
    ],
    qualities: [
        {
            icon: "line-icon-Code-Window text-fastblue",
            title: "Vaardig in Code-Optimalisatie",
            content: "Je bent bedreven in het optimaliseren van zowel bestaande als eigen geschreven code. Je focust op efficiëntie tijdens het programmeren, waarbij je best practices en nieuwe methoden combineert.",
        },
        {
            icon: "line-icon-Arrow-Refresh text-fastblue",
            title: "Adaptief en Leergierig",
            content: "Je past je snel aan bij nieuwe technologische ontwikkelingen en leert voortdurend bij. Deze aanpassingsvermogen en leergierigheid maken je een waardevolle en veelzijdige kracht in elk IT-team.",
        },
        {
            icon: "line-icon-Happy text-fastblue",
            title: "Samenwerking en Communicatie",
            content: "Je bent sterk in teamwerk en communiceert effectief met collega's. Jouw bijdrage bevordert niet alleen de projectvoortgang, maar versterkt ook de teamgeest en samenwerking binnen het bedrijf.",
        }
    ],
    techStack: [
        {
            img: "/assets/img/webp/java-icon.webp",
            title: "Java 11, 17 of 21"
        },
        {
            img: "/assets/img/webp/spring-icon.webp",
            title: "Spring Framework"
        },
        {
            img: "/assets/img/webp/postgresql-icon.webp",
            title: "Relationele Database(s)"
        },
        {
            img: "/assets/img/webp/html-css-icon.webp",
            title: "HTML en CSS"
        },
    ]
};